import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../environments/environment';
declare var gtag: Function;

@Injectable()
export class GoogleAnalyticsService {
  constructor(public router: Router) {
   if (!environment.production) return; // <-- If you want to enable GA only in production

    this.router.events.subscribe(event => {
      try {
        if (typeof gtag === 'function') {
          if (event instanceof NavigationEnd) {
            gtag('set', 'page', event.urlAfterRedirects);
            gtag('send', 'pageview');
          }
        }
      } catch (e) {
        if (!environment.production) console.error(e);
      }
    });

  }

  /**
   * Emit google analytics event
   * Fire event example:
   * this.emitEvent("testCategory", "testAction", "testLabel", 10);
   * @param {string} eventCategory
   * @param {string} eventAction
   * @param {string} eventLabel
   * @param {number} eventValue
   */
  public emitEvent(eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null) {
    if (!environment.production) return; // <-- If you want to enable GA only in production

    if (typeof gtag === 'function') {
      gtag('send', 'event', {
        eventCategory: eventCategory,
        eventLabel: eventLabel,
        eventAction: eventAction,
        eventValue: eventValue
      });
    }
  }

  public sendSignupConversion() {
    if (!environment.production) return; // <-- If you want to enable GA only in production
    gtag('event', 'conversion', { 'send_to': 'AW-1057702787/YnJ6CMSL1_4YEIOHrfgD' });
  }

  public sendPurchaseConversion(transaction_id) {
    if (!environment.production) return; // <-- If you want to enable GA only in production
    gtag('event', 'conversion', {
      'send_to': 'AW-1057702787/n-HUCK3l1_4YEIOHrfgD',
      'transaction_id': transaction_id
    });
  }
}