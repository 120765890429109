import { NgModule } from '@angular/core';
import { FilterPipe } from '../../_pipes/filter-pipe';
import { MaskPipe } from '../../_pipes/mask-pipe';
import { WattUOMPipe } from '../../_pipes/watt-uom-pipe';
import { WattPipe } from 'src/app/_pipes/watt.pipe';
import { PrettyPrintPipe } from 'src/app/_pipes/pretty.print.pipe';
import { CapabilitySortPipe } from 'src/app/_pipes/capability.sort.pipe';
import { FileSizePipe } from 'src/app/_pipes/file.size.pipe';
import { CommonModule } from '@angular/common';
import { DateAgoPipe } from 'src/app/_pipes/date-ago.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FilterPipe, MaskPipe, WattUOMPipe, WattPipe, PrettyPrintPipe, CapabilitySortPipe, FileSizePipe, DateAgoPipe
  ],
  exports: [
    FilterPipe, MaskPipe, WattUOMPipe, WattPipe, PrettyPrintPipe, CapabilitySortPipe, FileSizePipe, DateAgoPipe
  ]
})

export class SharedModule { }