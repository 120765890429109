import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards';

const appRoutes: Routes = [
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
    { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
    { path: 'forgotpassword', loadChildren: () => import('./forgot-password/forgotpassword.module').then(m => m.ForgotPasswordModule)},
    { path: 'changepassword', loadChildren: () => import('./change-password/change.password.module').then(m => m.ChangePasswordModule)},
    { path: 'users/activation/:id', loadChildren: () => import('./account-activation/account.activation.module').then(m => m.AccountActivationModule) },   
    { path: 'users/resetpassword/:id', loadChildren: () => import('./reset-password/reset.password.module').then(m => m.ResetPasswordModule) },  
    { path: 'payments/:id', loadChildren: () => import('./payments/payment.authorize.module').then(m => m.PaymentAuthorizeModule), canActivate: [AuthGuard] },
    { path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard] },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);