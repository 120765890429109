import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { NotifierOptions } from "angular-notifier";

export class Constants {
    public static readonly CAPABILITY_POWER = "sinric.capability.power";
    public static readonly CAPABILITY_MODE = "sinric.capability.mode";
    public static readonly CAPABILITY_RANGE = "sinric.capability.range";
    public static readonly CAPABILITY_CHANNEL = "sinric.capability.channel";
    public static readonly CAPABILITY_ENTERTAINMENT_INPUT_CONTROL = "sinric.capability.entertainment.input.control";
    public static readonly CAPABILITY_ENTERTAINMENT_INPUT_KEYPAD = "sinric.capability.entertainment.input.keypad";
    public static readonly CAPABILITY_ENTERTAINMENT_MEDIA_CONTROL = "sinric.capability.entertainment.media.control";
    public static readonly CAPABILITY_THERMOSTAT = "sinric.capability.thermostat";
    public static readonly CAPABILITY_TOGGLE = "sinric.capability.toggle";
    public static readonly CAPABILITY_ENERGY_USAGE_ESTIMATION = "sinric.capability.energy.usage.estimation";
    public static readonly CAPABILITY_SETTING = "sinric.capability.setting";

    public static readonly DEFAULT_BLIND_INSTANCE_NAME = "Blind.Lift";
    public static readonly VERSION_REGEX = /^(?<major>\d+)\.(?<minor>\d+)\.(?<patch>\d+)$/;

    public static readonly POPUP_MODEL_CONFIG : NgbModalOptions = {
      backdrop : 'static',
      keyboard : false
    };

    public static readonly GLOBLE_NOTIFIER_OPTION: NotifierOptions = {
        position: {
          horizontal: {
            position: 'middle',
            distance: 7
          },
          vertical: {
            position: 'top',
            distance: 110,
            gap: 10
          }
        },
        theme: 'material',
        behaviour: {
          autoHide: 5000,
          onClick: 'hide',
          onMouseover: 'pauseAutoHide',
          showDismissButton: true,
          stacking: 2
        },
        animations: {
          enabled: true,
          show: {
            preset: 'slide',
            speed: 300,
            easing: 'ease'
          },
          hide: {
            preset: 'fade',
            speed: 300,
            easing: 'ease',
            offset: 50
          },
          shift: {
            speed: 300,
            easing: 'ease'
          },
          overlap: 150
        }
    };
}